import {clubs} from './constants'
export function loadSelection() {
    const l = localStorage.getItem("CLUB")
    if (typeof l === 'string' && clubs.includes(l)) return l;
    else {
      saveSelection(clubs[0])
      return clubs[0]
    }
}

export function saveSelection(newValue) {
  localStorage.setItem("CLUB", newValue)
}

export function loadShots() {
    const r = localStorage.getItem("SHOTS")
    if (!r) return []
    try {
      const p = JSON.parse(r)
    if (!Array.isArray(p)) return []
    return p.filter(i => {
      return i._id
    })
    } catch(e) {
      saveShots([])
      return []
    }
  
  }

  export function saveShots(shots) {
    if (Array.isArray(shots)) localStorage.setItem("SHOTS", JSON.stringify(shots))
    else console.error("ignorning setting shots", typeof shots, shots)
  }