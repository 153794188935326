<template>
  <div>
    <canvas ref="myChartCanvas" width="400" height="400"></canvas>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import Chart from 'chart.js/auto';
import { useShotsStore } from '../store';

const store = useShotsStore();
const myChartCanvas = ref(null);
let myChart = null;

onMounted(() => {
  if (store.activeShotsOverTime && store.activeShotsOverTime.length) {
    makeGraph(store.activeShotsOverTime);
  } else {
    console.log("No items to display in the graph.");
  }
});

watch(() => store.makeGraph, (n) => {
  console.log("Make graph:", n);
  if (n) {
    makeGraph(store.activeShotsOverTime);
    store.makeGraph = false;
  }
});

watch(() => store.club, (n) => {
  console.log("Make graph new club:", n);
  if (n) {
    makeGraph(store.activeShotsOverTime);
    store.makeGraph = false;
  }
});

function makeGraph(data) {
  const averages = data.map(item => item.averageCarry);
  const labels = data.map((item) => item.day);
  const ctx = myChartCanvas.value.getContext("2d");
  
  // Destroy existing chart if it exists
  if (myChart) {
    myChart.destroy();
  }

  myChart = new Chart(ctx, {
    type: 'line',
    data: {
      labels,
      datasets: [{
        label: 'Average Carry Distance',
        data: averages,
        borderColor: 'rgb(75, 192, 192)',
        borderWidth: 1
      }]
    },
    options: {
      scales: {
        x: {
          title: {
            display: true,
            text: "Day"
          }
        },
        y: {
          title: {
            display: true,
            text: "Average Carry Distance"
          }
        }
      }
    }
  });
}
</script>

<style scoped>
/* Add any custom styles here */
</style>
