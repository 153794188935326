import { defineStore } from 'pinia';
import { saveSelection, saveShots } from '../localStorageService';
import { fetchShots, shotsOverTime } from '../api';
import { pgaAverages } from '../constants';

const player = "J";

export const useShotsStore = defineStore({
  id: 'shots',
  state: () => ({
    shotsInfo: [],
    club: "Driver",
    shotsOverTime: [],
    makeGraph: false
  }),
  getters: {
    activeShotsOverTime() {
      return this.shotsOverTime.find(shots => shots[0].club === this.club);
    },
    pgaAverage() {
      return pgaAverages.find(a => a.club === this.club) || {};
    }
  },
  actions: {
    async init() {
      try {
        const shots = await fetchShots(player);
        if (Array.isArray(shots)) {
          const sortedShots = shots.sort((a, b) => b.average_carry_distance - a.average_carry_distance);
          this.updateShotsInfo(sortedShots);
        }
        const shotsTime = await shotsOverTime(player);
        this.updateShotsOverTime(shotsTime);
        this.makeGraph = true;
      } catch (error) {
        console.error('Error initializing data:', error);
      }
    },
    updateClub(c) {
      this.club = c;
      this.makeGraph = true;
      saveSelection(c);
    },
    updateShotsInfo(shots) {
      this.shotsInfo = shots;
      saveShots(shots);
    },
    updateShotsOverTime(shots) {
      this.shotsOverTime = shots;
    },
  },
  // Define a hook to load state from localStorage
  onCreatePersistedState() {
    return () => {
      const storedState = localStorage.getItem('myStore');
      if (storedState) {
        this.replaceState(JSON.parse(storedState));
      }
    };
  },
  // Define a method to persist state to localStorage
  persistState() {
    localStorage.setItem('myStore', JSON.stringify(this.$state));
  },
});
