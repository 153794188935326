<template>
  <div class="card">
    <h5>File Upload</h5>
    <form @submit.prevent="submitForm" enctype="multipart/form-data">
      <input type="file" name="file" ref="fileInput" required>
      <button type="submit">Upload</button>
    </form>
    <div v-if="uploading">Uploading...</div>
    <div v-else>{{ status }}</div>
  </div>
</template>


<script setup>
import { ref } from 'vue';
import {upload} from "../api"
 

import { useShotsStore } from '../store';

// Get the store instance
const store = useShotsStore();

const status = ref('');

const uploading = ref(false)

const fileInput = ref(null)

const submitForm = async () => {
  const formData = new FormData(); // Create FormData object
  formData.append('csv', fileInput.value.files[0]);
  uploading.value = true;
  const result = await upload(formData)
  uploading.value = false
  status.value = result
  store.dispatch("init", "J")

};

 
</script>
