<template>
  <div v-if="!shotsInfo.length">no shots</div>
    <table v-else class="table table-hover text-right" style="min-width: 500px">
      <thead>
        <tr>
          <th class="text-left">Club Type</th>
          <th>Shots</th>
            <th>{{header('average_carry_distance')}}</th>
            <th>{{header('max_carry_distance')}}</th>
            <th>{{header('dispersion_carry_distance')}}</th>
            <th>{{header('average_total_distance')}}</th>
            <th>{{header('max_total_distance')}}</th>
            <th>{{header('dispersion_total_distance')}}</th>
            <th>{{header('average_smash_factor')}}</th>
            <th>{{header('max_smash_factor')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in shotsInfo" :key="item._id">
          <td class="text-left">{{ item._id }}</td>
          <td>{{item.count}}</td>
          <td>{{item.average_carry_distance.toFixed(0)}}</td>
          <td>{{item.max_carry_distance.toFixed(0)}}</td>
          <td>{{item.dispersion_carry_distance.toFixed(0)}}</td>
          <td>{{item.average_total_distance.toFixed(0)}}</td>
          <td>{{item.max_total_distance.toFixed(0)}}</td>
          <td>{{item.dispersion_total_distance.toFixed(0)}}</td>
          <td>{{item.average_smash_factor.toFixed(2)}}</td>
          <td>{{item.max_smash_factor.toFixed(2)}}</td>
        </tr>
      </tbody>
    </table>
  </template>
  
  <script setup>

import { useShotsStore } from '../store';
import { ref, watch } from 'vue';

 
    const store = useShotsStore();
    const shotsInfo = ref(store.shotsInfo);

    // Watch for changes in store's shotsInfo and update shotsInfo ref accordingly
    watch(() => store.shotsInfo, (newShotsInfo) => {
      shotsInfo.value = newShotsInfo;
    });

 
 

  function header(n) {
    return n.replace("_", " ")
  }
  </script>