<template>
<div class="card" style="max-width: 400px;">
    <label>{{ label }}</label>
    <select v-model="selectedOption">
    <option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
    </select>
</div>
</template>

<script setup>
import { ref, watch } from 'vue';
import {clubs} from '../constants'

import { useShotsStore } from '../store';

// Get the store instance
const store = useShotsStore();
const selectedOption = ref(store.club);

const options = clubs.map(club => ({value: club, label: club}))

const label = "select club"

// Emit an event when the selected option changes
watch(selectedOption, (newValue) => {
    store.updateClub(newValue)
});
</script>
