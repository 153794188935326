<template>
  <div class="container">
    <ShotsInfo/>
    <ShotsUpload/>
    <ClubSelector/>
    <ShotsOverTimeGraph/>
  </div>
</template>

<script setup>
 import { onMounted } from 'vue';
 import ShotsUpload from './components/ShotsUpload.vue'
 import ShotsInfo from './components/ShotsInfo.vue';
 import { useShotsStore } from './store';
import ShotsOverTimeGraph from './components/ShotsOverTimeGraph.vue';
import ClubSelector from './components/ClubSelector.vue'

// Get the store instance
const store = useShotsStore();

onMounted(() => store.init());
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
