export async function upload(formData) {
  try {
      const response = await fetch('https://golfapi.joechimienti.com/upload', {
        method: 'POST',
        body: formData
      });
  
      if (response.ok) {
        const bod = await(response.json())
        return 'File uploaded successfully. inserted=' + bod.inserted;
      } else {
        return 'Error uploading file.';
      }
    } catch (error) {
      return "error uploading file"
    }
}

export const fetchData = async (player) => {
  try {
    const response = await fetch('https://golfapi.joechimienti.com/shots/' + player);
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    return await response.json();
  } catch (error) {
    return error.message;
  }
};

export const fetchShots = async (player) => {
  try {
    const response = await fetch('https://golfapi.joechimienti.com/shotsInfo/' + player);
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    return await response.json();
  } catch (error) {
    return error.message;
  }
};

export async function shotsOverTime(player) {
  try {
    const response = await fetch('https://golfapi.joechimienti.com/shotsOverTime/' + player);
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    return await response.json();
  } catch (error) {
    return error.message;
  }

}