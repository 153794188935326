export const player = "J"
export const clubs = ["Driver","3 Wood","3 Hybrid","4 Iron","5 Iron","6 Iron","7 Iron","8 Iron","9 Iron", "Pitching Wedge","Gap Wedge","Sand Wedge","Lob Wedge"]
 
export const pgaAverages = [
    {
      "club": "Driver",
      "speedMph": 113,
      "attackAngle": -1.3,
      "ballSpeedMph": 167.0,
      "smashFactor": 1.48,
      "launchAngleDegrees": 10.9,
      "spinRate": 2686,
      "maxHeightYards": 32.0,
      "landAngleDeg": 38.0,
      "carryYards": 275.0
    },
    {
      "club": "3 Wood",
      "speedMph": 107,
      "attackAngle": -2.9,
      "ballSpeedMph": 158.0,
      "smashFactor": 1.48,
      "launchAngleDegrees": 9.2,
      "spinRate": 3655,
      "maxHeightYards": 30.0,
      "landAngleDeg": 43.0,
      "carryYards": 243.0
    },
    {
      "club": "5 Wood",
      "speedMph": 103,
      "attackAngle": -3.3,
      "ballSpeedMph": 152.0,
      "smashFactor": 1.47,
      "launchAngleDegrees": 9.4,
      "spinRate": 4350,
      "maxHeightYards": 31.0,
      "landAngleDeg": 47.0,
      "carryYards": 230.0
    },
    {
      "club": "3 Hybrid",
      "speedMph": 100,
      "attackAngle": -3.5,
      "ballSpeedMph": 146.0,
      "smashFactor": 1.46,
      "launchAngleDegrees": 10.2,
      "spinRate": 4437,
      "maxHeightYards": 29.0,
      "landAngleDeg": 47.0,
      "carryYards": 225.0
    },
    {
      "club": "3 Iron",
      "speedMph": 98,
      "attackAngle": -3.1,
      "ballSpeedMph": 142.0,
      "smashFactor": 1.45,
      "launchAngleDegrees": 10.4,
      "spinRate": 4630,
      "maxHeightYards": 27.0,
      "landAngleDeg": 46.0,
      "carryYards": 212.0
    },
    {
      "club": "4 Iron",
      "speedMph": 96,
      "attackAngle": -3.4,
      "ballSpeedMph": 137.0,
      "smashFactor": 1.43,
      "launchAngleDegrees": 11.0,
      "spinRate": 4836,
      "maxHeightYards": 28.0,
      "landAngleDeg": 48.0,
      "carryYards": 203.0
    },
    {
      "club": "5 Iron",
      "speedMph": 94,
      "attackAngle": -3.7,
      "ballSpeedMph": 132.0,
      "smashFactor": 1.41,
      "launchAngleDegrees": 12.1,
      "spinRate": 5361,
      "maxHeightYards": 31.0,
      "landAngleDeg": 49.0,
      "carryYards": 194.0
    },
    {
      "club": "6 Iron",
      "speedMph": 92,
      "attackAngle": -4.1,
      "ballSpeedMph": 127.0,
      "smashFactor": 1.38,
      "launchAngleDegrees": 14.1,
      "spinRate": 6231,
      "maxHeightYards": 30.0,
      "landAngleDeg": 50.0,
      "carryYards": 183.0
    },
    {
      "club": "7 Iron",
      "speedMph": 90,
      "attackAngle": -4.3,
      "ballSpeedMph": 120.0,
      "smashFactor": 1.33,
      "launchAngleDegrees": 16.3,
      "spinRate": 7097,
      "maxHeightYards": 32.0,
      "landAngleDeg": 50.0,
      "carryYards": 172.0
    },
    {
      "club": "8 Iron",
      "speedMph": 87,
      "attackAngle": -4.5,
      "ballSpeedMph": 115.0,
      "smashFactor": 1.32,
      "launchAngleDegrees": 18.1,
      "spinRate": 7998,
      "maxHeightYards": 31.0,
      "landAngleDeg": 50.0,
      "carryYards": 160.0
    },
    {
      "club": "9 Iron",
      "speedMph": 85,
      "attackAngle": -4.79,
      "ballSpeedMph": 109.0,
      "smashFactor": 1.28,
      "launchAngleDegrees": 20.4,
      "spinRate": 8647,
      "maxHeightYards": 30.0,
      "landAngleDeg": 51.0,
      "carryYards": 148.0
    },
    {
      "club": "Pitching Wedge",
      "speedMph": 83,
      "attackAngle": -5.0,
      "ballSpeedMph": 102.0,
      "smashFactor": 1.23,
      "launchAngleDegrees": 24.2,
      "spinRate": 9304,
      "maxHeightYards": 29.0,
      "landAngleDeg": 52.0,
      "carryYards": 136.0
    }
  ]
  